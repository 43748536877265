// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

// require("@rails/ujs").start()
// require("channels")
import LazyLoad from "vanilla-lazyload";
import SmoothScroll from 'smooth-scroll';
import Cookies from 'js-cookie';

import * as Sentry from "@sentry/browser";
import { Integrations } from "@sentry/tracing";

require('lightgallery');
require('lg-fullscreen');
require('lg-share');
require('lg-autoplay');
require('lg-hash');
require('lg-thumbnail');
require('lg-zoom');

require('magnific-popup');
// require('picturefill');


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
const videos = require.context('../videos', true)
// const imagePath = (name) => images(name, true)

import '../mapplic/mapplic.js';

import '../mapplic/mapplic.css';
import 'lightgallery/dist/css/lightgallery.min.css';
import '../styles/application.scss';
import 'magnific-popup/dist/magnific-popup.css';

import jqueryEasing from 'jquery.easing';

Sentry.init({
  dsn: "https://c6041b83ccb64217872e2f53fe677866@o491105.ingest.sentry.io/5639558",
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: 0,
});

const DOMReady = function(callback) {
  if (document.readyState === 'interactive' || document.readyState === 'complete')
    callback()
  else
    document.addEventListener('DOMContentLoaded', callback);
};

DOMReady(function() {
  var myLazyLoad = new LazyLoad({
    elements_selector: "img.lazy"
  });

  var videoLazyLoad = new LazyLoad({
    elements_selector: "video.lazy"
  });

  const mapplicHeight = $(window).width() > 768 ? 770 : Math.round($(window).width() * 0.7);
  setTimeout(function() {
    const mapContainer = $('#plotsMap');
    const investmentId = mapContainer.data('investment-id');

    const mapplicMap = $('#plotsMap').mapplic({
      source: `/investments/${investmentId}/plots-map.json`,
      iconfile: "/icons.svg",
      height: mapplicHeight,
      minimap: false,
      minheight: 250,
      sidebar: false,
      hovertip: true,
      // deeplinking: false,
      developer: false,
      lightbox: true,
      maxscale: 1,
      zoom: false,
      zoombuttons: false
    }).data('mapplic');

    $(document).on('click', '.plotsList-action a', function() {
      const plotId = $(this).data('id');
      window.scrollTo(0, $('#mapa').offset().top);
      mapplicMap.showLocation(plotId, 100);

      return false;
    });
  }, 250);

  $('#mobileBars').on('click', function() {
    $('#mobileBars').toggleClass('open');
    return false;
  });

  $(document).on('click', '.homePlots-actions a', function(e) {
    e.preventDefault();
    var navLink = $(this);

    $.get(navLink.prop('href'), function(response) {
      var ajaxContent = $(response);
      var plotsList = ajaxContent.find('.plotsList-row');

      $('.homePlots .plotsList').append(plotsList);
      $('.homePlots-actions').replaceWith(ajaxContent.find('.homePlots-actions'));
    })
  })

  window.addEventListener('scroll', function() {
    if (window.pageYOffset) {
      document.body.classList.add('scrolled');
    } else {
      document.body.classList.remove('scrolled');
    }
  });

  var scroll = new SmoothScroll('a[href*="#"][data-scroll]', {
    speed: 200,
    // speedAsDuration: true
  });
  document.addEventListener('scrollStart', function() {
    $('#mobileBars').removeClass('open');
  }, false);

  $('.homeGallery').lightGallery({
    thumbnail: true,
    animateThumb: false,
    showThumbByDefault: false
  });

  $('.homeBox-link').lightGallery({
  });

  $('#new_contact_email').on('submit', function(e) {
    e.preventDefault();
    const form = $(this);
    const formData = form.serialize();

    $.post(form.prop('action'), formData, function(response) {
      if (response.form) {
        var box = $('<div>').html(response.form);
        $('#new_contact_email').html(box.find('#new_contact_email').html());
      }
    }, 'json');
  });

  $(document).on('submit', '#plot_form', function(e) {
    e.preventDefault();
    const form = $(this);
    const formData = form.serialize();

    $.post(form.prop('action'), formData, function(response) {
      if (response.form) {
        var box = $('<div>').html(response.form);
        $('#plot_form').html(box.find('#plot_form').html());
      }
    }, 'json');
  });

  $('.textBox a.read-more').on('click', function(e) {
    e.preventDefault();

    $(this).parents('.textBox').next('.textBoxMore').slideDown(300);

    $(this).hide();
  });
})

DOMReady(function() {
  $('.homeFaq .faqQuestion .faqQuestion-title').on('click', function() {
    const answerBox = $(this).siblings('.faqQuestion-answer');
    const questionBox = $(this).parents('.homeFaq .faqQuestion');
    if (questionBox.hasClass('open')) {
      answerBox.slideUp(300);
      questionBox.removeClass('open');
    } else {
      $('.homeFaq .faqQuestion .faqQuestion-answer').slideUp(300);
      $('.homeFaq .faqQuestion').removeClass('open');
      answerBox.slideDown(300);
      questionBox.addClass('open');
    }
  });
});

DOMReady(function() {
  if (Cookies.get('hideCookiePolicy')) {
    $('.cookie_info').hide();
  } else {
    $('.cookie_info').show();
  }
  $('.cookie_info .cookie_info--close').on('click', function() {
    $('.cookie_info').fadeOut(300);
    Cookies.set('hideCookiePolicy', 'yes', {
      expires: 365
    });
    return false;
  });
});


DOMReady(function() {
  function getTimeRemaining(endtime){
    const total = Date.parse(endtime) - Date.parse(new Date());
    const seconds = Math.floor( (total/1000) % 60 );
    const minutes = Math.floor( (total/1000/60) % 60 );
    const hours = Math.floor( (total/(1000*60*60)) % 24 );
    const days = Math.floor( total/(1000*60*60*24) );

    return {
      total,
      days,
      hours,
      minutes,
      seconds
    };
  }

  function pluralize(value, form1, form2, form3) {
    if (value == 1) {
      return `${value} ${form1}`;
    } else if (value % 10 >= 2 && value % 10 <= 4 && (value % 100 < 10 || value % 100 > 20)) {
      return `${value} ${form2}`;
    } else {
      return `${value} ${form3}`;
    }
  }

  const clockTimeout = setInterval(refreshTime, 1000)

  function refreshTime() {
    const timers = document.querySelectorAll('.discount-timer')
    let activeTimers = 0

    if (timers.length > 0) {
      timers.forEach(timer => {
        const timerTime = timer.dataset.time
        const timeObject = getTimeRemaining(timerTime)
        
        if (timeObject.total > 0) {
          let remainingTime = '';
          if (timeObject.days > 1) {
            remainingTime += `${timeObject.days} dni, `;
          } else if (timeObject.days == 1) {
            remainingTime += `${timeObject.days} dzień, `;
          }
          remainingTime += `${pluralize(timeObject.hours, 'godzina', 'godziny', 'godzin')}, `;
          remainingTime += `${pluralize(timeObject.minutes, 'minuta', 'minuty', 'minut')}, `;
          remainingTime += `${pluralize(timeObject.seconds, 'sekunda', 'sekundy', 'sekund')}, `;
          remainingTime = remainingTime.slice(0, -2);
    
          timer.innerHTML = remainingTime
          activeTimers += 1
        } else {
          timer.style.display = 'none';
        }
      });
      if (activeTimers == 0) {
        clearTimeout(clockTimeout)
      }
    }
  }
  refreshTime();
});
